import ImageXComponent from '@wix/thunderbolt-elements/src/components/ImageX/viewer/ImageX';


const ImageX = {
  component: ImageXComponent
};


export const components = {
  ['ImageX']: ImageX
};

